<template>
<div>
        <loading :active.sync="loading"></loading>
        <!--<link rel="stylesheet" href="https://unpkg.com/@coreui/icons/css/all.min.css">-->
        <div class="row"><div class="col-md-12">
          <b-card style="max-width:1050px;">
            <template v-slot:header>
            <div class="row">
                <div class="col-md-4">
                <strong>Gera Preço {{ nomeTabela[$route.params.id || 1] }}</strong>
                <div v-if="loadingMeta"><span style="color:red;font-weight:bold;"><img src="/img/loading_col.gif" alt="Em uso" />&nbsp;2/2 - Calculando preços sugeridos...</span></div>
                <div v-if="loadingTbl"><span style="color:red;font-weight:bold;"><img src="/img/loading_col.gif" alt="Em uso" />&nbsp;1/2 - Carregando tabela...</span></div>
                </div>
                <div class="col-md-8">
                  <div align="right">
                  <b-button variant="secondary" size="sm" @click="mcBox()">MC {{ mcminima }}% Mínimo</b-button>
                  
                  <a
                  class="btn btn-link" 
                  href="javascript:;"
                  @click="showModalSimples()" >
                  <CIcon name="cil-cloud-upload" />&nbsp;<small>Importar</small>
                  </a>
                  
                  <download-excel
                  class="btn btn-link" 
                  :data   = "matExcel"
                  name = "precificador.xls"
                   >
                  <CIcon name="cil-cloud-download" />&nbsp;<small>Exportar</small>
              </download-excel>
                <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
                  </div>
                </div>
            </div>
          </template>
          <b-row>
          <b-col lg="6" md="12" sm="12" class="my-1">
            <b-form-group
              label="Busca"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0 mt-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Digite para procurar produtos..." 
                  v-on:keyup="searchItems"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
          <b-card-body>
                <b-alert :show="alertaFaturamento" variant="danger">
                  <p><strong>Alerta de média de faturamento</strong></p>
                  <p>O resultado do Gera Preço pode ser impreciso caso não informe mês a mês o faturamento formal de sua empresa.</p>
                  <a href="/#/configuracoes/impostos" class="btn btn-info">Alimentar planilha de faturamento</a>
                </b-alert>
                <b-alert :show="mcminimaAlert" variant="danger">
                  <p><strong>Margem de Contribuição Mínima está zerada</strong></p>
                  <p>Você precisa definir uma margem de contribuição mínima (MC Mínimo) para que o Gera Preço funcione adequadamente.</p>
                </b-alert>
                <b-tabs>
                  <b-tab :title="qtdeProdutos" active>
                    <div class="table-scroll" :style="autoH">
                        <table class="table table-hover table-sm">
                            <thead>
                            <tr>
                              <th data-v-step="1" class="fixo grey" :style="colunaFixa1"></th>
                              <th class="fixo grey" :style="colunaFixa2"></th>
                              <th class="fixo grey" :style="colunaFixa3"></th>
                              <th class="fixo grey" :style="colunaFixa4">Identificadores</th>
                              <th class="grey" style="min-width:90px;"></th>
                              <th class="grey" id="tooltip-1" style="min-width:110px;"><u>1</u></th>
                              <th class="grey" id="tooltip-b" style="min-width:70px;"><u>2</u></th>
                              <th class="grey" id="tooltip-c" style="min-width:70px;"><u>3</u></th>
                              <th class="grey" id="tooltip-d" style="min-width:70px;"><u>4</u></th>
                              <th class="grey" id="tooltip-e" style="min-width:70px;"><u>5</u></th>
                              <th class="grey" id="tooltip-f" style="min-width:70px;"><u>6</u></th>
                              <th class="grey" id="tooltip-ca" style="min-width:70px;"><u>7</u></th>
                              <th class="grey" id="tooltip-cb" style="min-width:70px;"><u>8</u></th>
                              <th class="grey" id="tooltip-cc" style="min-width:70px;"><u>9</u></th>
                              <th class="grey" id="tooltip-g" style="min-width:70px;"><u>10</u></th>

                              <th class="grey" id="tooltip-h" style="min-width:120px;"><u>11</u></th>
                              <th class="grey" id="tooltip-i" style="min-width:120px;"><u>12</u></th>
                              <th class="grey" id="tooltip-2" style="min-width:80px;"><u>13</u></th>
                              <th class="grey" id="tooltip-3" style="min-width:80px;"><u>14</u></th>
                              <th class="grey" id="tooltip-4" style="min-width:120px;"><u>15</u></th>
                            </tr>
                            <tr>
                                <th class="fixo" :style="colunaFixa1"></th>
                                <th class="fixo centro" :style="colunaFixa2">Linha</th>
                                <th class="fixo centro" :style="colunaFixa3">Cód.</th>
                                <th class="fixo centro" :style="colunaFixa4">Produto</th>
                                <th class="centro" style="min-width:90px;">Unid. Venda</th>
                                
                                <th class="centro" style="min-width:110px;">Custo Direto</th>
                                
                                <th class="centro" style="min-width:90px;">Custo Direto %</th>
                                <th class="centro" style="min-width:70px;">Impostos</th>
                                <th class="centro" style="min-width:70px;">ISS/ICMS</th>
                                <th class="centro" style="min-width:70px;">Taxa CC</th>
                                <th class="centro" style="min-width:70px;">Comissão 1</th>
                                <th class="centro" style="min-width:70px;"><a style="color:white;text-decoration:underline;" href="javascript:;" @click="campoBox(3)" v-html="ocampo[3]"></a></th>
                                <th class="centro" style="min-width:70px;"><a style="color:white;text-decoration:underline;" href="javascript:;" @click="campoBox(1)" v-html="ocampo[1]"></a></th>
                                <th class="centro" style="min-width:70px;"><a style="color:white;text-decoration:underline;" href="javascript:;" @click="campoBox(2)" v-html="ocampo[2]"></a></th>
                                <th class="centro" style="min-width:70px;">Total %</th>

                                <th class="centro" style="min-width:120px;"><img v-if="loadingMeta" src="/img/loading_col.gif" alt="Em uso" />Preço Sugerido</th>
                                <th class="centro" style="min-width:120px;">Preço Venda 1</th>
                                <th class="centro" style="min-width:90px;">Total CV %</th>
                                <th class="centro" style="min-width:90px;">MC %</th>
                                <th class="centro" style="min-width:120px;">MC {{ this.$session.get('regras')[0].grp_moeda }}</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(prdItem, index) in prd" :key="index">
                                    <th :style="colunaFixa1">
                                        <!--<b-form-checkbox
                                        v-model="prd[index].prd_active" 
                                        size="sm" 
                                        switch 
                                        @change.native="editar(index)"
                                        ></b-form-checkbox>-->
                                        <div class="numeracao">{{ ((index + 1) < 10 ? String('0' + (index + 1)) : (index + 1)) }}</div>
                                    </th>
                                    <th :style="colunaFixa2" data-title="Linha">
                                        <b-form-input class="form-control laranja" readonly v-model="prd[index].prd_linha_name"></b-form-input>
                                    </th>
                                    <th :style="colunaFixa3" data-title="Cód.">
                                        <b-form-input class="laranja" readonly v-model="prd[index].prd_code"></b-form-input>
                                    </th>
                                    <th :style="colunaFixa4" data-title="Produto">
                                        <b-form-input class="laranja" readonly :title="prd[index].prd_name" v-model="prd[index].prd_name"></b-form-input>
                                    </th>
                                    <td data-title="Unidade">
                                      <b-form-input class="laranja" readonly v-model="prd[index].prd_descr"></b-form-input>
                                    </td>
                                    <td data-title="Custo Direto">
                                        <vue-autonumeric class="form-control amarelao direita" style="font-size:10px;" :options="dinheiro" readonly v-model="prd[index].prd_custodireto_total"></vue-autonumeric>
                                    </td>
                                    <td data-title="Custo Direto %" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric class="form-control amarelo direita" style="font-size:10px;" :options="percentual" readonly v-model="prd[index].custo_direto_total"></vue-autonumeric>
                                    </td>
                                    <td data-title="Impostos" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric class="form-control amarelo direita" style="font-size:10px;" :options="percentual" readonly v-model="prd[index].enc_total_imposto"></vue-autonumeric>
                                    </td>
                                    <td data-title="ISS/ICMS" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric @blur.native="editar(index)" class="form-control verde direita" style="font-size:10px;" :options="percentual" v-model="prd[index]['prd_impostos' + paramID]"></vue-autonumeric>
                                    </td>
                                    <td data-title="Taxa CC" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric @blur.native="editar(index)" class="form-control verde direita" style="font-size:10px;" :options="percentual" v-model="prd[index]['prd_outros03' + paramID]"></vue-autonumeric>
                                    </td>
                                    <td data-title="Comissão 1" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric @blur.native="editar(index)" class="form-control verde direita" style="font-size:10px;" :options="percentual" v-model="prd[index]['prd_com01' + paramID]"></vue-autonumeric>
                                    </td>
                                    <td data-title="Outros 1" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric @blur.native="editar(index)" class="form-control verde direita" style="font-size:10px;" :options="percentual" v-model="prd[index]['prd_com02' + paramID]"></vue-autonumeric>
                                    </td>
                                    <td data-title="Outros 2" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric @blur.native="editar(index)" class="form-control verde direita" style="font-size:10px;" :options="percentual" v-model="prd[index]['prd_outros01' + paramID]"></vue-autonumeric>
                                    </td>
                                    <td data-title="Outros 3" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric @blur.native="editar(index)" class="form-control verde direita" style="font-size:10px;" :options="percentual" v-model="prd[index]['prd_outros02' + paramID]"></vue-autonumeric>
                                    </td>
                                    <td data-title="Total %" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric class="form-control amarelo direita" style="font-size:10px;" :options="percentual" readonly v-model="prd[index].prd_outros_total"></vue-autonumeric>
                                    </td>
                                    <td data-title="Preço Sug." v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric class="form-control amarelo direita" style="font-size:10px;" :options="dinheiro" readonly v-model="prd[index].prd_precosugerido"></vue-autonumeric>
                                    </td>
                                    <td data-title="Preço Venda" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric @blur.native="editar(index)" class="form-control verde direita" style="font-size:10px;" :options="dinheiro" v-model="prd[index]['prd_precovenda' + paramID]"></vue-autonumeric>
                                    </td>
                                    <td data-title="Total CV %" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric class="form-control amarelo direita" style="font-size:10px;" :options="percentual" readonly v-model="prd[index].total_cv"></vue-autonumeric>
                                    </td>
                                    <td data-title="MC %" v-if="mcminima > prd[index].mc_total_perc">
                                        <vue-autonumeric v-if="prd[index].prd_tipo !== 2" class="form-control vermelho direita" style="font-size:10px;" :options="percentual" readonly v-model="prd[index].mc_total_perc"></vue-autonumeric>
                                    </td>
                                    <td data-title="MC %" v-else>
                                        <vue-autonumeric v-if="prd[index].prd_tipo !== 2" class="form-control amarelo direita" style="font-size:10px;" :options="percentual" readonly v-model="prd[index].mc_total_perc"></vue-autonumeric>
                                    </td>
                                    <td data-title="MC" v-if="prd[index].prd_tipo !== 2">
                                        <vue-autonumeric class="form-control amarelo direita" style="font-size:10px;" :options="dinheiro" readonly v-model="prd[index].mc_total"></vue-autonumeric>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                  </b-tab>
                </b-tabs>
          </b-card-body>
          </b-card>
            <b-tooltip target="tooltip-a" triggers="hover">
            Percentual que representa a perda de produtos fabricados
            </b-tooltip>
            <b-tooltip target="tooltip-b" triggers="hover">
            Custo direto (1) / Preço de Venda (12)
            </b-tooltip>
            <b-tooltip target="tooltip-c" triggers="hover">
            Percentual de imposto, importado de Configurações > Regime Tributário
            </b-tooltip>
            <b-tooltip target="tooltip-d" triggers="hover">
            ISS ou ICMS adicional
            </b-tooltip>
            <b-tooltip target="tooltip-e" triggers="hover">
            Taxa média cobrada pelas operadoras de cartões
            </b-tooltip>
            <b-tooltip target="tooltip-f" triggers="hover">
            Comissão do vendedor
            </b-tooltip>
            <b-tooltip target="tooltip-g" triggers="hover">
            Soma total das colunas (3) a (9)
            </b-tooltip>
            <b-tooltip target="tooltip-h" triggers="hover">
            Preço para se obter a MC% mínima
            </b-tooltip>
            <b-tooltip target="tooltip-i" triggers="hover">
            Preço de venda efetivo
            </b-tooltip>

            <b-tooltip target="tooltip-ca" triggers="hover">
            Taxa customizada pelo usuário
            </b-tooltip>
            <b-tooltip target="tooltip-cb" triggers="hover">
            Taxa customizada pelo usuário
            </b-tooltip>
            <b-tooltip target="tooltip-cc" triggers="hover">
            Taxa customizada pelo usuário
            </b-tooltip>
            
            
            <b-tooltip target="tooltip-1" triggers="hover">
            Insumos + Mão de Obra + Perda
            </b-tooltip>
             <b-tooltip target="tooltip-2" triggers="hover">
            Total do custo variável: (10) + (2)
            </b-tooltip>
            <b-tooltip target="tooltip-3" triggers="hover">
            Margem de contribuição efetiva (em vermelho se menor que o MC% mínimo)
            </b-tooltip>
            <b-tooltip target="tooltip-4" triggers="hover">
            <strong>MARGEM DE CONTRIBUIÇÃO EM REAIS</strong><br>
            <em>É o que sobra para a empresa depois de tirar do Preço de Venda todos os Custos Variáveis. Alguns chamam de Lucro Bruto.</em>
            <br>
            <p>{{ comentarios }}</p>
            <br><b-button size="sm" @click="comentarioBox()">Editar comentários adicionais</b-button>
            </b-tooltip>
            <v-tour name="myTour" :steps="steps" :options="stepsOptions"></v-tour>
          </div>
        </div>
        <a v-if="isMobile()" href="javascript:;" @click="scrollBusca()" class="btnfloat">
      <CIcon name="cil-find-in-page" class="my-btnfloat" />
    </a>
    <b-modal ref="my-insumo" hide-footer no-close-on-backdrop no-close-on-esc size="lg" v-model="myInsumo">
      <insumo ref="insumodiv" :id="idinsumo" :embed="true" :key="componentKey"></insumo>
    </b-modal>
    <b-modal ref="my-mo" hide-footer no-close-on-backdrop no-close-on-esc size="lg" v-model="myMo">
      <maodeobra ref="modiv" :id="idmo" :embed="true" :key="componentKey"></maodeobra>
    </b-modal>
    <b-modal size="lg" ref="modal-simples" hide-footer title="Inserção em massa">
      <b-alert variant="info" show dismissible>
        <p>Utilize o modelo de planilha abaixo para subir todas as taxas e comissões (colunas 4 a 9) de uma só vez.<br>
        Você pode utilizar o EXCEL para copiar e colar aqui.<br>
        A ordem segue naturalmente igual ao Gera Preço.</p>
      </b-alert>
      <b-alert variant="danger" v-if="isMobile()" show>
        <p><strong>Desculpe!</strong> Este recurso no momento é incompatível com dispositivos móveis.</p>
      </b-alert>
      <hot-table ref="dataTables" :data="dataTable" :settings="dataTableSet"></hot-table>
      <div class="row">
        <div class="col-md-8">
          <b-button variant="success" :disabled="adicionou" @click="salvaSimplesData()">Aplicar</b-button>
          &nbsp;&nbsp;<b-button variant="danger" size="sm" @click="removeSimplesData()">Limpar</b-button>
        </div>
        <div class="col-md-4">
          <b-progress :value="vTbl" :max="maxTbl" show-progress animated></b-progress>
        </div>
      </div>
    </b-modal>
    <b-modal size="lg" id="ajuda" hide-footer title="Gera Preço">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/kgNDD8TnalY?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
    <div class="adicionar-foot"><button id="naoesquecadesalvar" class="btn btn-success" title="Clique aqui para salvar as alterações" :disabled="indexes.length === 0" @click="salvartudo()">SALVAR ALTERAÇÕES</button></div>
        <b-tooltip :show="naoesqueca" target="naoesquecadesalvar">
          <p><strong>Não esqueça de salvar!</strong></p>
          <p>Clique aqui para salvar as modificações.</p>
        </b-tooltip>
</div>
</template>
<style>
table.htCore tr td:nth-child(5) {
  background-color: #CCFFCC !important;
}
</style>
<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'
import Insumo from './MaterialUsoModal'
import Maodeobra from './MaodeobraUsoModal'
import { HotTable } from '@handsontable/vue'

export default {
  data () {
    return {
      nomeTabela: {
        1 : '1',
        2 : '2',
        3 : '3' 
      },
      stepsOptions: {
        labels: {
          buttonSkip: 'Sair da ajuda',
          buttonPrevious: 'Anterior',
          buttonNext: 'Próximo',
          buttonStop: 'OK!'
        }
      },
      steps: [
        {
          target: '[data-v-step="1"]',
          header: {
            title: 'Gera Preço',
          },
          content: `Ainda estamos montando a tela de ajuda...`
        }
      ],
      paramID: '',
      matExcel: [],
      loading: false,
      loadingMeta: false,
      loadingTbl: false,
      naoesqueca: false,
      indexes: [],
      colunaFixa1: 'min-width:1rem; left: 0;',
      colunaFixa2: 'min-width:90px; left: 16px;',
      colunaFixa3: 'min-width:90px; left: 106px;',
      colunaFixa4: 'min-width:160px; left: 196px;',
      autoH: 'height:calc(100vh - 312px)',
      prd: [],
      prdOriginal: [],
      filter: '',
      carregando: '<div align="right"><img src="/img/loading.gif" alt="Aguarde..." /></div>',
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      percentual2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 1
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      qtdeProdutos: 'Produtos',
      qtdePreparos: 'Preparos',
      prep: [],
      comentarios: '',
      mcminima: 0,
      mcminimaAlert: false,
      ocampo: ['', 'Outros 1', 'Outros 2', 'Comissão 2'],
      alertaFaturamento: false,
      encargo_val: 0,
      idinsumo: null,
      insumos_preparo: [],
      idmo: null,
      componentKey: 0,
      myInsumo: false,
      myMo: false,
      adicionou: false,
      vTbl: 0,
      maxTbl: 0,
      dataTable: [],
      dataTableSet: {
        colHeaders: ['ISS/ICMS', 'Taxa CC', 'Comissão 1', 'Extra 1', 'Extra 2', 'Extra 3'],
        rowHeaders: true,
        width: 500,
        height: 400,
        colWidths: [100, 100, 100, 100, 100, 100],
        minRows: 10,
        columns: [
          {
            type: 'numeric',
            numericFormat: {
              culture: 'pt-BR',
              pattern: '0,0.00'
            }
          },
          {
            type: 'numeric',
            numericFormat: {
              culture: 'pt-BR',
              pattern: '0,0.00'
            }
          },
          {
            type: 'numeric',
            numericFormat: {
              culture: 'pt-BR',
              pattern: '0,0.00'
            }
          },
          {
            type: 'numeric',
            numericFormat: {
              culture: 'pt-BR',
              pattern: '0,0.00'
            }
          },
          {
            type: 'numeric',
            numericFormat: {
              culture: 'pt-BR',
              pattern: '0,0.00'
            }
          },
          {
            type: 'numeric',
            numericFormat: {
              culture: 'pt-BR',
              pattern: '0,0.00'
            }
          }
        ],
        licenseKey: 'non-commercial-and-evaluation'
      }
    }
  },
  watch: {
    myInsumo: function () {
      if (!this.myInsumo) {
        this.idinsumo = null
        this.componentKey += 1
        this.recarregar(true)
      }
    },
    myMo: function () {
      if(!this.myMo) {
        this.idmo = null
        this.componentKey += 1
        this.recarregar(true)
      }
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    if (screen.width <= 760) {
      this.colunaFixa1 = 'left: 0px;'
      this.colunaFixa2 = 'left: 0px;'
      this.colunaFixa3 = 'left: 0px;'
      this.colunaFixa4 = 'left: 0px;'
      this.autoH = 'height:100%'
    }
    this.verificaSessao()
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.verificaSessao()
      }
    }
  },
  filters: {
    grana: function (num) {
      if (typeof num !== 'undefined') {
        return String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      }
    },
    somarArray: function (value) {
      var ret = 0
      for (var i = 0; i < value.length; i++) {
        ret = ret + parseFloat(value[i])
      }
      return ret
    }
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    scrollBusca: function () {
      document.getElementById('filterInput').focus()
      window.scrollTo(0, 100)
    },
    verificaSessao: function () {
      this.mcminimaAlert = false
      this.paramID = (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id)
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('PRECIFICADOR'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        // Nome das tabelas
        this.listar('gp_Metadados', 'id', '(module LIKE opcoes_tabelas)').then(
          (m) => {
            for (var i = 0; i < m.length; i++) {
              if (m[i].string === 'tabela1') {
                this.nomeTabela[1] = ' - ' + m[i].description
              }
              if (m[i].string === 'tabela2') {
                this.nomeTabela[2] = ' - ' + m[i].description
              }
              if (m[i].string === 'tabela3') {
                this.nomeTabela[3] = ' - ' + m[i].description
              }
            }
          }
        )
        this.verificaFaturamento()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    recarregar: function (simples) {
      this.loadingTbl = true
      // Listando preparos e produtos
      this.prep = []
      this.prd = []
      this.insumos_preparo = []
      this.campo('pro_Encargo', 'enc_total_imposto', '(enc_active = 1)').then(
        (en) => {
          // Busca os valores de insumos
          this.listar('pro_Material', 'prd_ID', '(prd_ID > 0)').then(
            (x) => {
              for (var i = 0; i < x.length; i++) {
                this.insumos_preparo[x[i].prd_ID] = x[i].mat_custohora
              }
              var usoParams = {
                g: this.$session.get('grupo'),
                en: en,
                dash: 0,
                tipo: 1,
                param: this.paramID
              }
              this.$http.post(this.URLApp + 'tabelas/filtroProdutoGeraPreco', usoParams).then(
                (r) => {
                  this.loadingTbl = false
                  if (r.body.length === 0) {
                    this.$swal('Nenhum produto cadastrado', 'O precificador só é acessível caso preencha todas as tabelas necessárias para compor o produto desejado. Utilize esta tela para criar seus produtos e o menu TABELAS para criar insumos e mão de obra.', 'error')
                    this.$router.push('/configuracoes/produtos')
                  } else {
                    this.montaGeraPreco(r.body, simples)
                  }
                },
                (r) => {
                  this.$swal('Nenhum produto detectado ou falha ao buscar produtos', 'O precificador só é acessível caso preencha todas as tabelas necessárias para compor o produto desejado. Utilize esta tela para criar seus produtos e o menu TABELAS para criar insumos e mão de obra.', 'error')
                  this.$router.push('/configuracoes/produtos')
                  this.loadingTbl = false
                }
              )
            }
          )
        }, 
        (en) => {
          this.$notify({
            type: 'error',
            title: 'Encargos indefinidos',
            text: 'É necessário definir os encargos em Configurações.'
          })
        }
      )
    },
    montaGeraPreco: function (r, simples) {
      this.prep = []
      this.prd = []
      this.dataTable = []
      for (var i = 0; i < r.length; i++) {
        if (r[i].prd_maodeobra_total === null) {
          // Cria a "mao de obra zero"
          // this.inserir('pro_Produto_Maodeobra', {resource: [ {prd_ID: r[i].prd_ID, mao_ID: 1, pmo_num: 1, pmo_qtde: 0, pmo_medida: 'hora', pmo_active: 1} ] }, '', '1')
          r[i].prd_maodeobra_total = 0
        }
        if (r[i].prd_insumos_total === null) {
          // this.inserir('pro_Produto_Material', {resource: [ {prd_ID: r[i].prd_ID, pmt_mat_ID: 1, pmt_qtde: 0, pmt_active: 1} ] }, '', '1')
          r[i].prd_insumos_total = 0
        }
        r[i].prd_precosugerido = 0
        r[i].total_cv = (r[i].custo_direto_total + r[i].prd_outros_total)
        if (parseInt(r[i].prd_tipo) === 2) {
          r[i].prd_insumos_total = this.insumos_preparo[r[i].prd_ID]
          r[i].prd_custodireto_total = this.insumos_preparo[r[i].prd_ID] + r[i].prd_maodeobra_total
          this.prep.push(r[i])
        } else {
          this.prd.push(r[i])
        }
        this.matExcel.push({
          Linha: r[i].prd_linha_name,
          Nome: r[i].prd_name,
          Unid_Prod: r[i].prd_descr,
          Cod: r[i].prd_code,
          Insumos: (r[i].prd_insumos_total || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3, style: 'currency', currency: 'BRL'}),
          Mao_de_obra: (r[i].prd_maodeobra_total || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3, style: 'currency', currency: 'BRL'}),
          Custo_Direto: (r[i].prd_custodireto_total || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3, style: 'currency', currency: 'BRL'}),
          Impostos: (r[i].prd_total_imposto || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%',
          ISS_ICMS: (r[i]['prd_impostos' + this.paramID] || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%',
          Taxa_CC: (r[i]['prd_outros03' + this.paramID] || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%',
          Comissao_1: (r[i]['prd_com01' + this.paramID] || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%',
          Outros_1: (r[i]['prd_com02' + this.paramID] || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%',
          Outros_2: (r[i]['prd_outros01' + this.paramID] || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%',
          Outros_3: (r[i]['prd_outros02' + this.paramID] || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%',
          Total_Perc: (r[i].prd_outros_total || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%',
          Preco_Venda: (r[i]['prd_precovenda' + this.paramID] || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3, style: 'currency', currency: 'BRL'}),
          Custo_Direto_Perc: (r[i].custo_direto_total || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%',
          MC_Perc: (r[i].mc_total_perc || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%',
          MC_Total: (r[i].mc_total || 0).toLocaleString('pt-BR', {minimumFractionDigits: 3, style: 'currency', currency: 'BRL'})
        })
        this.dataTable.push(
          [r[i]['prd_impostos' + this.paramID], r[i]['prd_outros03' + this.paramID], r[i]['prd_com01' + this.paramID], r[i]['prd_com02' + this.paramID], r[i]['prd_outros01' + this.paramID], r[i]['prd_outros02' + this.paramID]]
        )
      }
      this.qtdePreparos = (this.prep.length === 1 ? this.prep.length + ' Preparo' : this.prep.length + ' Preparos')
      this.qtdeProdutos = (this.prd.length === 1 ? this.prd.length + ' Produto' : this.prd.length + ' Produtos')
      this.prdOriginal = this.prd
      if (!simples) {
        this.loadingMeta = true
        this.metadataCheck()
      } else {
        if (this.mcminima > 0) {
          this.mcminimaAlert = false
          // Calcula o preco de venda de acordo com a MC mínimo
          for (var j = 0; j < this.prd.length; j++) {
            this.prd[j].prd_precosugerido = (this.prd[j].prd_custodireto_total / ((100 - this.prd[j].prd_outros_total - this.mcminima) / 100))
          }
        } else {
          this.mcminimaAlert = true
        }
      }
    },
    metadataCheck: function () {
      let tmpPage = (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id)
      let condicao = '(module LIKE precificador) AND ((string LIKE campo01' + tmpPage + ') OR (string LIKE campo02' + tmpPage + ') OR (string LIKE campo03' + tmpPage + ') OR (string LIKE mcobs' + tmpPage + ') OR (string LIKE mcminima' + tmpPage + '))'
      this.listar('gp_Metadados', 'id', condicao).then(
        (m) => {
          this.mcminima = 0
          this.comentarios = ''
          this.ocampo[1] = 'Editar'
          this.ocampo[2] = 'Editar'
          this.ocampo[3] = 'Editar'
          if (m.length === 0) {
            var p = []
            p.push(this.inserir('gp_Metadados', {resource: [ {string: 'campo01' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', module: 'precificador'} ]}, '', '1'))
            p.push(this.inserir('gp_Metadados', {resource: [ {string: 'campo02' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', module: 'precificador'} ]}, '', '1'))
            p.push(this.inserir('gp_Metadados', {resource: [ {string: 'campo03' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', module: 'precificador'} ]}, '', '1'))
            p.push(this.inserir('gp_Metadados', {resource: [ {string: 'mcobs' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', module: 'precificador'} ]}, '', '1'))
            p.push(this.inserir('gp_Metadados', {resource: [ {string: 'mcminima' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', module: 'precificador'} ]}, '', '1'))
            Promise.all(p).then(
              (k) => { this.loadingMeta = false },
              (k) => { this.loadingMeta = false }
            )
          } else {
            for (var i = 0; i < m.length; i++) {
              if (m[i].string === 'mcobs' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '') {
                this.comentarios = m[i].description
              }
              if (m[i].string === 'mcminima' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '') {
                this.mcminima = m[i].description
              }
              if (m[i].string === 'campo01' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '') {
                this.ocampo[1] = m[i].description
                if (this.ocampo[1] === '' || this.ocampo[1] === null) {
                  this.ocampo[1] = 'Editar'
                }
              }
              if (m[i].string === 'campo02' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '') {
                this.ocampo[2] = m[i].description
                if (this.ocampo[2] === '' || this.ocampo[2] === null) {
                  this.ocampo[2] = 'Editar'
                }
              }
              if (m[i].string === 'campo03' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '') {
                this.ocampo[3] = m[i].description
                if (this.ocampo[3] === '' || this.ocampo[3] === null) {
                  this.ocampo[3] = 'Editar'
                }
              }
            }
            if (this.mcminima > 0) {
              this.mcminimaAlert = false
              // Calcula o preco de venda de acordo com a MC mínimo
              for (var j = 0; j < this.prd.length; j++) {
                this.prd[j].prd_precosugerido = (this.prd[j].prd_custodireto_total / ((100 - this.prd[j].prd_outros_total - this.mcminima) / 100))
              }
            } else {
              this.mcminimaAlert = true
            }
            this.loadingMeta = false
          }
        },
        (m) => {
          this.mcminima = 0
          this.comentarios = ''
          this.ocampo[1] = 'Editar'
          this.ocampo[2] = 'Editar'
          this.ocampo[3] = 'Editar'
          var p = []
          p.push(this.inserir('gp_Metadados', {resource: [ {string: 'campo01' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', module: 'precificador'} ]}, '', '1'))
          p.push(this.inserir('gp_Metadados', {resource: [ {string: 'campo02' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', module: 'precificador'} ]}, '', '1'))
          p.push(this.inserir('gp_Metadados', {resource: [ {string: 'campo03' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', module: 'precificador'} ]}, '', '1'))
          p.push(this.inserir('gp_Metadados', {resource: [ {string: 'mcobs' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', module: 'precificador'} ]}, '', '1'))
          p.push(this.inserir('gp_Metadados', {resource: [ {string: 'mcminima' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', module: 'precificador'} ]}, '', '1'))
          Promise.all(p).then(
            (k) => { this.loadingMeta = false },
            (k) => { this.loadingMeta = false }
          )
        }
      )
    },
    editar: function (index, force) {
      if (!force) {
        this.loading = false
        this.indexes.push(index)
        if (this.indexes.length === 1) {
          this.naoesqueca = true
        }
        return false
      }
      this.indexes = []
      let tmpPage = (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id)
      this.loading = true
      var r = {resource: [ 
        {prd_impostos: (this.prd[index]['prd_impostos' + tmpPage] ?? 0), 
        prd_active: this.prd[index]['prd_active'], 
        prd_com01: (this.prd[index]['prd_com01' + tmpPage] ?? 0),
        prd_com02: (this.prd[index]['prd_com02' + tmpPage] ?? 0),
        prd_outros03: (this.prd[index]['prd_outros03' + tmpPage] ?? 0),
        prd_outros01: (this.prd[index]['prd_outros01' + tmpPage] ?? 0),
        prd_outros02: (this.prd[index]['prd_outros02' + tmpPage] ?? 0),
        prd_precovenda: (this.prd[index]['prd_precovenda' + tmpPage] ?? 0)
        } 
      ]}
      if (tmpPage.length > 0) {
        r['resource'][0]['prd_impostos' + tmpPage] = r['resource'][0]['prd_impostos']
        delete r['resource'][0]['prd_impostos']
        r['resource'][0]['prd_com01' + tmpPage] = r['resource'][0]['prd_com01']
        delete r['resource'][0]['prd_com01']
        r['resource'][0]['prd_com02' + tmpPage] = r['resource'][0]['prd_com02']
        delete r['resource'][0]['prd_com02']
        r['resource'][0]['prd_outros03' + tmpPage] = r['resource'][0]['prd_outros03']
        delete r['resource'][0]['prd_outros03']
        r['resource'][0]['prd_outros01' + tmpPage] = r['resource'][0]['prd_outros01']
        delete r['resource'][0]['prd_outros01']
        r['resource'][0]['prd_outros02' + tmpPage] = r['resource'][0]['prd_outros02']
        delete r['resource'][0]['prd_outros02']
        r['resource'][0]['prd_precovenda' + tmpPage] = r['resource'][0]['prd_precovenda']
        delete r['resource'][0]['prd_precovenda']
      }
      this.atualizar('pro_Produto', r, 'prd_ID = ' + this.prd[index].prd_ID, '', '1').then(
        (response) => {
          this.recarregar(true)
        },
        (response) => {
          this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
          this.loading = false
        }
      )
    },
    editarloop: function (index) {
      let tmpPage = (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id)
      if (this.prd[index]['prd_precovenda' + tmpPage] < 0) {
        return -1
      }
      var r = {resource: [ 
        {prd_impostos: (this.prd[index]['prd_impostos' + tmpPage] ?? 0), 
        prd_active: this.prd[index]['prd_active'], 
        prd_com01: (this.prd[index]['prd_com01' + tmpPage] ?? 0),
        prd_com02: (this.prd[index]['prd_com02' + tmpPage] ?? 0),
        prd_outros03: (this.prd[index]['prd_outros03' + tmpPage] ?? 0),
        prd_outros01: (this.prd[index]['prd_outros01' + tmpPage] ?? 0),
        prd_outros02: (this.prd[index]['prd_outros02' + tmpPage] ?? 0),
        prd_precovenda: (this.prd[index]['prd_precovenda' + tmpPage] ?? 0)
        } 
      ]}
      if (tmpPage.length > 0) {
        r['resource'][0]['prd_impostos' + tmpPage] = r['resource'][0]['prd_impostos']
        delete r['resource'][0]['prd_impostos']
        r['resource'][0]['prd_com01' + tmpPage] = r['resource'][0]['prd_com01']
        delete r['resource'][0]['prd_com01']
        r['resource'][0]['prd_com02' + tmpPage] = r['resource'][0]['prd_com02']
        delete r['resource'][0]['prd_com02']
        r['resource'][0]['prd_outros03' + tmpPage] = r['resource'][0]['prd_outros03']
        delete r['resource'][0]['prd_outros03']
        r['resource'][0]['prd_outros01' + tmpPage] = r['resource'][0]['prd_outros01']
        delete r['resource'][0]['prd_outros01']
        r['resource'][0]['prd_outros02' + tmpPage] = r['resource'][0]['prd_outros02']
        delete r['resource'][0]['prd_outros02']
        r['resource'][0]['prd_precovenda' + tmpPage] = r['resource'][0]['prd_precovenda']
        delete r['resource'][0]['prd_precovenda']
      }
      return this.atualizar('pro_Produto', r, 'prd_ID = ' + this.prd[index].prd_ID, '', '1').then(
        (response) => {
          return this.prd[index].prd_ID
        },
        (response) => {
          return -2
        }
      )
    },
    salvartudo: function () {
      this.loading = true
      if (this.indexes.length === 0) {
        this.loading = false
        this.naoesqueca = false
        this.$swal('Não há nada a ser salvo aqui.')
        return false
      }
      var i = this.indexes.filter((v, i, a) => a.indexOf(v) === i)
      var index = 0
      var p = []
      var ind = []
      for (var idx in i) {
        index = i[idx]
        p.push(this.editarloop(index))
        ind.push(index)
      }
      Promise.all(p).then(
        (r) => {
          console.log(r)
          for (var y = 0; y < r.length; y++) {
            if (r[y] === -1) {
              this.loading = false
              this.$swal('Falha ao salvar', 'Erro no registro da linha ' + (ind[y] + 1) + ': valor incorreto.', 'error')
              return false
            }
            if (r[y] === -2) {
              this.loading = false
              this.$swal('Falha ao salvar', 'Erro no registro da linha ' + (ind[y] + 1) + ': falha interna ao salvar registro.', 'error')
              return false
            }
          }
          this.loading = false
          this.indexes = []
          this.naoesqueca = false
          this.recarregar(true)
        },
        (r) => {
          this.loading = false
          this.naoesqueca = false
          this.$swal('Falha ao salvar um ou mais registros. Verifique sua conexão.')
          return false
        }
      )
    },
    salvarLinha: function (index) {
      let tmpPage = (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id)
      var r = {resource: [ 
        {prd_impostos: (this.prd[index]['prd_impostos' + tmpPage] ?? 0), 
        prd_active: this.prd[index]['prd_active'], 
        prd_com01: (this.prd[index]['prd_com01' + tmpPage] ?? 0),
        prd_com02: (this.prd[index]['prd_com02' + tmpPage] ?? 0),
        prd_outros03: (this.prd[index]['prd_outros03' + tmpPage] ?? 0),
        prd_outros01: (this.prd[index]['prd_outros01' + tmpPage] ?? 0),
        prd_outros02: (this.prd[index]['prd_outros02' + tmpPage] ?? 0),
        prd_precovenda: (this.prd[index]['prd_precovenda' + tmpPage] ?? 0)
        } 
      ]}
      if (tmpPage.length > 0) {
        r['resource'][0]['prd_impostos' + tmpPage] = r['resource'][0]['prd_impostos']
        delete r['resource'][0]['prd_impostos']
        r['resource'][0]['prd_com01' + tmpPage] = r['resource'][0]['prd_com01']
        delete r['resource'][0]['prd_com01']
        r['resource'][0]['prd_com02' + tmpPage] = r['resource'][0]['prd_com02']
        delete r['resource'][0]['prd_com02']
        r['resource'][0]['prd_outros03' + tmpPage] = r['resource'][0]['prd_outros03']
        delete r['resource'][0]['prd_outros03']
        r['resource'][0]['prd_outros01' + tmpPage] = r['resource'][0]['prd_outros01']
        delete r['resource'][0]['prd_outros01']
        r['resource'][0]['prd_outros02' + tmpPage] = r['resource'][0]['prd_outros02']
        delete r['resource'][0]['prd_outros02']
        r['resource'][0]['prd_precovenda' + tmpPage] = r['resource'][0]['prd_precovenda']
        delete r['resource'][0]['prd_precovenda']
      }
      return this.atualizar('pro_Produto', r, 'prd_ID = ' + this.prd[index].prd_ID, '', '1').then(
          (r) => {
            return r
          },
          (r) => {
            return false
          }
        )
    },
    salvarTudo: function () {
      var p = []
      for (var i = 0; i < this.prd.length; i++) {
        p.push(this.salvarLinha(i))
      }
      Promise.all(p).then(
        (k) => {
          console.log(k)
          this.$refs['modal-simples'].hide()
          this.loading = false
          this.recarregar()
          return true
        },
        (k) => {
          console.log(k)
          this.$refs['modal-simples'].hide()
          this.loading = false
          this.recarregar()
          return false
        }
      )
    },
    comentarioBox: function () {
      const { value: text } = this.$swal({
        title: 'Margem de Contribuição (em dinheiro)',
        html: '<em>É o que sobra para a empresa depois de tirar do Preço de Venda todos os Custos Variáveis. Alguns chamam de Lucro Bruto.</em>',
        input: 'textarea',
        inputPlaceholder: 'Digite seus comentários aqui...',
        inputAttributes: {
          'aria-label': 'Digite seus comentários'
        },
        showCancelButton: false,
        inputValue: this.comentarios
      }).then(
        (a) => { 
          this.comentarios = a.value
          this.atualizar('gp_Metadados', {resource: [ {description: this.comentarios, module: 'precificador'} ]}, '(string LIKE mcobs' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + ') AND (module LIKE precificador) AND (grp_ID = ' + this.$session.get('grupo') + ')').then(
            (r) => {
              this.recarregar(true)
            },
            (r) => {}
          )
        },
        (a) => { this.comentarios = '' }
      )
    },
    mcBox: function () {
      const { value: text } = this.$swal({
        title: 'Margem de Contribuição (%)',
        html: 'Digite abaixo a margem de contribuição mínima',
        input: 'text',
        inputAttributes: {
          'style': 'text-align:center'
        },
        showCancelButton: false,
        inputValue: this.mcminima
      }).then(
        (a) => {
          if (!isNaN(a.value)) {
            this.mcminima = parseFloat(String(a.value).replace(',','.'))
            this.atualizar('gp_Metadados', {resource: [ {description: this.mcminima, module: 'precificador'} ]}, '(string LIKE mcminima' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + ') AND (module LIKE precificador) AND (grp_ID = ' + this.$session.get('grupo') + ')').then(
              (r) => {
                this.recarregar(true)
              },
              (r) => {}
            )
          }
        },
        (a) => { this.mcminima = 0 }
      )
    },
    campoBox: function (num) {
      const { value: text } = this.$swal({
        title: 'Renomear',
        html: 'Digite o novo nome para este campo',
        input: 'text',
        inputAttributes: {
          'style': 'text-align:center'
        },
        showCancelButton: false,
        inputValue: this.ocampo[num]
      }).then(
        (a) => { 
          this.ocampo[num] = a.value
          this.atualizar('gp_Metadados', {resource: [ {description: this.ocampo[num], module: 'precificador'} ]}, '(string LIKE campo0' + num + '' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + ') AND (module LIKE precificador) AND (grp_ID = ' + this.$session.get('grupo') + ')').then(
            (r) => {
              this.recarregar(true)
            },
            (r) => {}
          )
        },
        (a) => { this.mcminima = 0 }
      )
    },
    p: function (index) {
      this.$router.push('/configuracoes/produtos')
    },
    prp: function (index) {
      this.$router.push('/configuracoes/preparos')
    },
    i: function (index) {
      this.myInsumo = true
      this.idinsumo = this.prd[index].prd_ID
      // var p = this.prd[index].prd_ID
      // this.$router.push('/tabelas/produtosmateriais/' + p)
    },
    mo: function (index) {
      this.myMo = true
      this.idmo = this.prd[index].prd_ID
      //var p = this.prd[index].prd_ID
      //this.$router.push('/tabelas/maodeobrauso/' + p)
    },
    i2: function (index) {
      this.myInsumo = true
      this.idinsumo = this.prep[index].prd_ID
      // var p = this.prep[index].prd_ID
      // this.$router.push('/tabelas/produtosmateriais/' + p)
    },
    mo2: function (index) {
      this.myMo = true
      this.idmo = this.prep[index].prd_ID
      // var p = this.prep[index].prd_ID
      // this.$router.push('/tabelas/maodeobrauso/' + p)
    },
    verificaFaturamento: function () {
      // Ve qual encargo ele faz parte
      this.listar('pro_Encargo', 'enc_ID', 'enc_active = 1').then(
        (en) => {
          if (en.length === 0) {
            this.$swal('Grupo de encargos indefinido!', 'Está faltando definir os grupos de encargos na base desta empresa. Acesse o menu REGIME TRIBUTARIO (em Configurações) antes de acessar aqui.', 'warning')
            this.loading = false
            return false
          }
          for (var i = 0; i < en.length; i++) {
            var encargo = en[i].enc_ID
            var encargoNome = en[i].enc_name
            var encTotalImposto = en[i].enc_total_imposto
            if (encargoNome === 'Lucro Real' || encargoNome === 'MEI') {
              this.alertaFaturamento = false
              this.recarregar()
            } else {
              if (encTotalImposto === 0) {
                this.$notify({
                  type: 'error',
                  title: 'Erro ao buscar produtos e preparos (alíquota zerada ou indefinida)',
                  text: 'Confira suas configurações de regime tributário e encargos'
                })
                return false
              }
            }
            // Pega lista em metadados
            this.listar('gp_Metadados', 'id', '(module LIKE impostos)').then(
              (response) => {
                var mes_descr = []
                var mes_valor = []
                var osmeses = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez']
                var mesAtual = (new Date().getMonth() + 1)
                var fatur_1, fatur_2, fatur_3, fatur_4, fatur_5, fatur_6, fatur_7, fatur_8, fatur_9, fatur_10, fatur_11, fatur_12 = -1
                var anexo_ativo = 0
                var dup = []
                for (var i = 0; i < response.length; i++) {
                  if (dup.includes(response[i].string)) {
                    this.remover('gp_Metadados', 'id = ' + response[i].id, '')
                  } else {
                    if (parseInt(response[i].description) >= 0) {
                      eval('' + response[i].string + ' = parseFloat(' + response[i].description + ');')
                    }
                    if (String(response[i].date_created).indexOf('-01') >= 0 && response[i].string.indexOf('fatur_') >= 0) {
                      mes_descr[response[i].string] = response[i].date_created
                      mes_valor[(new Date(response[i].date_created).getMonth() + 1)] = parseFloat(response[i].description)
                    } else {
                      mes_descr[response[i].string] = null
                    }
                  }
                  dup.push(response[i].string)
                }
                // Se não existe fatur_1, nem prossegue
                if (fatur_1 === -1) {
                  this.$swal('Faturamento mensal não definido', 'Acesse o menu REGIME TRIBUTÁRIO (em Configurações) para que defina o faturamento mês a mês de sua empresa.', 'warning')
                  this.loading = false
                  return false
                }

                // Constroi um novo array para atualizar a planilha
                var faturNovo = {
                  'fatur_1': {'data': this.corrigeMes(mesAtual - 1), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 1).split('-')[1])]},
                  'fatur_2': {'data': this.corrigeMes(mesAtual - 2), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 2).split('-')[1])]},
                  'fatur_3': {'data': this.corrigeMes(mesAtual - 3), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 3).split('-')[1])]},
                  'fatur_4': {'data': this.corrigeMes(mesAtual - 4), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 4).split('-')[1])]},
                  'fatur_5': {'data': this.corrigeMes(mesAtual - 5), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 5).split('-')[1])]},
                  'fatur_6': {'data': this.corrigeMes(mesAtual - 6), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 6).split('-')[1])]},
                  'fatur_7': {'data': this.corrigeMes(mesAtual - 7), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 7).split('-')[1])]},
                  'fatur_8': {'data': this.corrigeMes(mesAtual - 8), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 8).split('-')[1])]},
                  'fatur_9': {'data': this.corrigeMes(mesAtual - 9), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 9).split('-')[1])]},
                  'fatur_10': {'data': this.corrigeMes(mesAtual - 10), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 10).split('-')[1])]},
                  'fatur_11': {'data': this.corrigeMes(mesAtual - 11), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 11).split('-')[1])]},
                  'fatur_12': {'data': this.corrigeMes(mesAtual - 12), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 12).split('-')[1])]},
                }
                var prom = []
                for (var v in faturNovo) {
                  prom.push(this.faturUpdate(faturNovo[v], v))
                }
                Promise.all(prom).then(
                  (r) => {
                    var mesfatur = new Date(mes_descr['fatur_1']).getMonth() // Mes anterior na planilha de faturamento
                    var mesanterior = (((new Date().getMonth())-1) < 0 ? 11 : (new Date().getMonth())-1) // Mes anterior real
                    
                    // Verifica se o mes na planilha esta ok e se tem valor de faturamento:
                    if (osmeses[mesfatur] === osmeses[mesanterior] && fatur_1 > 0) {
                      this.alertaFaturamento = false
                    } else {
                      this.alertaFaturamento = true
                    }
                    this.recarregar()
                  },
                  (r) => {
                    this.alertaFaturamento = true
                    this.recarregar()
                  }
                )
              },
              (response) => {
                this.$swal('Falha ao buscar o faturamento da empresa', 'Acesse o menu REGIME TRIBUTÁRIO (em Configurações) e confirme as informações.', 'warning')
                this.loading = false
                return false
              }
            )
          }
        },
        (en) => {
          this.$swal('Grupo de encargos indefinido!', 'Está faltando definir os grupos de encargos na base desta empresa. Acesse o menu REGIME TRIBUTÁRIO (em Configurações) antes de acessar aqui.', 'warning')
          this.loading = false
          return false
        }
      )
    },
    corrigeMes: function (data) {
      // Pega o ano
      var ano = new Date().getFullYear()
      // Corrige o mes
      if (data <= 0) {
        if (data === 0) {
          data = 12
          ano = ano - 1
        } else {
          if (data === -12) {
            data = 12
            ano = ano - 2
          } else {
            data = 12 - ((data) * (-1))
            ano = ano - 1
          }
        }
      }
      return String(ano) + '-' + String(data).padStart(2,'0') + '-01'
    },
    faturUpdate: function (valores, variavel) {
      if (typeof valores.valor === 'undefined' || valores.valor === 0 || valores.valor === '' || valores.valor === 'undefined') {
        valores.valor = 0
      }
      this.campos('gp_Metadados', '(string LIKE ' + variavel + ') AND (module LIKE impostos)').then(
        (response) => {
          if (typeof response !== 'undefined') { // && parseFloat(response.description) !== valores.valor) {
            // Atualiza (só se for diferente)
            if (parseFloat(response.description) !== parseFloat(valores.valor) || valores.data !== response.date_created) {
              this.atualizar('gp_Metadados', {resource: [ {string: variavel, module: 'impostos', description: valores.valor, date_created: valores.data} ]}, 'id = ' + response.id, '', '1')
            }
          }
          if (typeof response === 'undefined') {
            // Nao existe. Cria
            this.inserir('gp_Metadados', {resource: [ {string: variavel, module: 'impostos', description: valores.valor, date_created: valores.data} ]}, '', '1')
          }
        }, 
        (response) => {
          // Nao existe. Cria.
          this.inserir('gp_Metadados', {resource: [ {string: variavel, module: 'impostos', description: valores.valor, date_created: valores.data} ]}, '', '1')
        }
      )
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    },
    searchItems() {
      if (this.filter === '') {
        this.prd = this.prdOriginal
        return
      }
      var searchedItens = []
      var campo1 = ''
      var campo2 = ''
      var campo3 = ''
      for (var i = 0; i < this.prdOriginal.length; i++) {
        campo1 = this.prdOriginal[i].prd_name ? String(this.prdOriginal[i].prd_name).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ''
        campo2 = this.prdOriginal[i].prd_code ? String(this.prdOriginal[i].prd_code).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ''
        campo3 = this.prdOriginal[i].prd_linha_name ? String(this.prdOriginal[i].prd_linha_name).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ''
        if (campo1.indexOf(this.filter.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0 || campo2.indexOf(this.filter.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0 || campo3.indexOf(this.filter.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0) {
          searchedItens.push(this.prdOriginal[i])
        }
      }
      this.prd = searchedItens
    },
    showModalSimples: function () {
      this.vTbl = 0
      this.dataTableSet.minRows = this.prd.length
      this.$refs['modal-simples'].show()
    },
    salvaSimplesData: function () {
      if (this.dataTable.length === 0) {
        alert('Tabela vazia. Nada a ser aplicado...')
        return false
      }
      let tmpPage = (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id)
      this.loading = true
      this.adicionou = true
      this.maxTbl = ( this.dataTable.length - 1 )
      this.vTbl = 0
      var a = []
      var b = []
      var c = 0
      for (var i = 0; i < this.dataTable.length; i++) {
        a = []
        b = []
        if (typeof this.dataTable[i][0] === 'undefined' || this.dataTable[i][0] === '' || this.dataTable[i][0] === null || isNaN(this.dataTable[i][0])) {
          this.prd[i]['prd_impostos' + tmpPage] = 0
        } else {
          this.prd[i]['prd_impostos' + tmpPage] = parseFloat(this.dataTable[i][0])
        }
        if (typeof this.dataTable[i][1] === 'undefined' || this.dataTable[i][1] === '' || this.dataTable[i][1] === null || isNaN(this.dataTable[i][1])) {
          this.prd[i]['prd_outros03' + tmpPage] = 0
        } else {
          this.prd[i]['prd_outros03' + tmpPage] = parseFloat(this.dataTable[i][1])
        }
        if (typeof this.dataTable[i][2] === 'undefined' || this.dataTable[i][2] === '' || this.dataTable[i][2] === null || isNaN(this.dataTable[i][2])) {
          this.prd[i]['prd_com01' + tmpPage] = 0
        } else {
          this.prd[i]['prd_com01' + tmpPage] = parseFloat(this.dataTable[i][2])
        }
        if (typeof this.dataTable[i][3] === 'undefined' || this.dataTable[i][3] === '' || this.dataTable[i][3] === null || isNaN(this.dataTable[i][3])) {
          this.prd[i]['prd_com02' + tmpPage] = 0
        } else {
          this.prd[i]['prd_com02' + tmpPage] = parseFloat(this.dataTable[i][3])
        }
        if (typeof this.dataTable[i][4] === 'undefined' || this.dataTable[i][4] === '' || this.dataTable[i][4] === null || isNaN(this.dataTable[i][4])) {
          this.prd[i]['prd_outros01' + tmpPage] = 0
        } else {
          this.prd[i]['prd_outros01' + tmpPage] = parseFloat(this.dataTable[i][4])
        }
        if (typeof this.dataTable[i][5] === 'undefined' || this.dataTable[i][5] === '' || this.dataTable[i][5] === null || isNaN(this.dataTable[i][5])) {
          this.prd[i]['prd_outros02' + tmpPage] = 0
        } else {
          this.prd[i]['prd_outros02' + tmpPage] = parseFloat(this.dataTable[i][5])
        }
        this.vTbl++
      }
      this.adicionou = false
      this.salvarTudo()
    },
    removeSimplesData: function () {
      this.dataTable = []
    }
  },
  components: {
    Loading,
    VueAutonumeric,
    Insumo,
    Maodeobra,
    HotTable
  }
}
</script>